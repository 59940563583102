<template>
  <div>
    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`struk-resep-${data.patient_name}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print
          :data="data"
          :setting="setting"
          :doctor="doctor"
          :patient="patient"
          :patientAge="patientAge"
          :splitMedicine="splitMedicine"
          :strMedicine="strMedicine"
          :displayMedicine="displayMedicine"
        />
      </section>
    </vue-html2pdf>

    <div class="row">
      <div class="col-md-8">
        <Print
          :data="data"
          :setting="setting"
          :doctor="doctor"
          :patient="patient"
          :patientAge="patientAge"
          :splitMedicine="splitMedicine"
          :strMedicine="strMedicine"
          :displayMedicine="displayMedicine"
        />
      </div>
      <div class="col-md-4">
        <b-button
          squared
          size="sm"
          variant="success"
          @click="btnPrintOnClick"
          v-b-tooltip.hover.right
          title="Print"
        ><i class="fas fa-print px-0"></i></b-button>
        <b-button
          squared
          :class="'d-block mt-2'"
          size="sm"
          variant="info"
          @click="btnDownloadOnClick"
          v-b-tooltip.hover.right
          title="Download"
        ><i class="fas fa-download px-0"></i></b-button>
        <b-button
          squared
          size="sm"
          :class="'d-block mt-2'"
          variant="success"
          @click="$router.push('/prescriptions/edit/' + data.id)"
          v-b-tooltip.hover.right
          title="Edit Resep"
          v-if="manipulateBtn == true"
        ><i class="fas fa-edit px-0"></i></b-button>
        <b-button
          squared
          size="sm"
          :class="'d-block mt-2'"
          variant="primary"
          @click="$router.push('/prescriptions/list')"
          v-b-tooltip.hover.right
          title="Daftar Resep"
        ><i class="fas fa-clipboard-list px-0"></i></b-button>
      </div>
    </div>

    <!-- Print -->
    <div
      id="printMe"
      class="d-none"
    >
      <div
        class="card"
        id="print"
        style="border: none"
      >
        <div class="card-body pt-4">
          <div
            class="main-prescription"
            style="min-height: 1280px"
          >
            <div class="row align-items-center">
              <div class="col-md">
                <h2 class="doctor">
                  <h5>{{ data.doctor_name }}</h5>
                </h2>
                <h4>{{ doctor.polyclinic_name }}</h4>
              </div>
             <ImageComponent place="logo-po"></ImageComponent>
            </div>
            <div class="panel-body mt-3">
              <table class="table">
                <tr>
                  <td>Tanggal: {{ data.display_date }}</td>
                  <td>Resep ID: {{ data.id }}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Pasien: {{ data.patient_name }}</td>
                  <td>Pasien ID: {{ data.patient_number }}</td>
                  <td>Umur: {{ patientAge }}</td>
                  <td>Kelamin: {{ patient.gender }}</td>
                </tr>
              </table>
            </div>
            <hr />
            <div
              class="col-md-12 clearfix"
              style="margin: 50px 0px"
            >
              <div class="row">
                <!-- <div class="col-md-5 left_panel">
                    <div class="panel-body">
                      <div class="pull-left">
                        <h5>
                          <strong>Riwayat: </strong> <br />
                          <br />
                          <p v-text="data.history"></p>
                        </h5>
                      </div>
                    </div>

                    <hr />

                    <div class="panel-body">
                      <div class="pull-left">
                        <h5>
                          <strong>Catatan:</strong> <br />
                          <br />
                          <p v-text="data.notes"></p>
                        </h5>
                      </div>
                    </div>

                    <hr />

                    <div class="panel-body">
                      <div class="pull-left">
                        <h5>
                          <strong>Saran: </strong> <br />
                          <br />
                          <p v-text="data.advice"></p>
                        </h5>
                      </div>
                    </div>
                  </div> -->
                <div class="col-md-12">
                  <div class="panel-body">
                    <div>
                      <table class="table">
                        <tr>
                          <th>Nama</th>
                          <th>Dosis</th>
                          <th>Frekuensi</th>
                          <th>Hari</th>
                          <th>Instruksi</th>
                        </tr>
                        <tr
                          v-for="mdc in displayMedicine"
                          :key="mdc.id"
                        >
                          <td>{{ mdc.name }}</td>
                          <td>{{ mdc.dosage }}</td>
                          <td>{{ mdc.frequency }}</td>
                          <td>{{ mdc.days }}</td>
                          <td>{{ mdc.instruction }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-4 pull-left"
              style="font-size: 12px; margin-top: 70px"
            >
              <hr />
              Tanda tangan
            </div>
            <div class="col-md-8 pull-right text-right mt-10">
              <!-- <h3 class="hospital">Hayandra</h3> -->
              <h6
                class="font-weight-light"
                style="font-size: 10px"
              >{{setting.address}}</h6>
              <h6
                class="font-weight-light"
                style="font-size: 10px"
              >{{setting.emergency}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Print from "@/component/prescriptions/Print.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueHtml2pdf from "vue-html2pdf";
import ImageComponent from "@/component/general/ImageComponent.vue";

export default {
  data() {
    return {
      // Detail Data
      data: {},
      doctor: {},
      patient: {},
      patientAge: "",
      splitMedicine: "",
      strMedicine: [],
      displayMedicine: [],
      // access management
      manipulateBtn: false,
      setting: {},
    };
  },

  components: {
    Card,
    VueHtml2pdf,
    Print,
    ImageComponent
  },

  methods: {
    async getSetting() {
      this.setting = await module.get('website-settings/get-default')
      if (this.setting == null) {
        this.setting = {
          address: "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430",
          phone: "(021) 3909333"
        }
      }
      console.log("setting", this.setting)
    },

    async getData(id) {
      this.data = await module.get(`prescriptions/${id}`);
      this.displayMedicine = JSON.parse(this.data.medicine);
      this.splitMedicine = this.data.medicine;
      this.strMedicine = this.splitMedicine.replace(
        'border="1">',
        'border="0">'
      );
      this.doctor = await module.list("doctors/" + this.data.doctor_id);
      this.patient = await module.list("patients/" + this.data.patient_id);
      this.patient = await module.list("patients/" + this.data.patient_id);
      this.getAge(this.patient.birt_date);
    },

    async getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      this.patientAge = age;
    },

    async deleteData() {
      let result = await module.delete(
        "prescriptions/" + this.$route.params.id
      );
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push("/prescriptions/list");
      }
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    timeOut(){
     return this.$htmlToPaper("printMe");
    },

    btnPrintOnClick() {
      setTimeout(this.timeOut,2000)
    },

    onProgress($event) {
      console.log("onProgress", $event);
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration");
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event);
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")

        if (access_right[a] == "8002") {
          this.manipulateBtn = true
        }

      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Resep Obat", route: "/prescriptions/list" },
      { title: "Detail Resep Obat", route: "/prescriptions/list" },
    ]);
    // Get Data
    this.getData(this.$route.params.id);
    this.setActiveMenu()
    this.getSetting()
  },
};
</script>

<style scoped>
hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid #000;
}

.panel-body {
  background: #f1f2f7;
}

thead {
  background: transparent;
}

.bg_prescription {
  min-height: 760px;
  margin-top: 10px;
}

.prescription_footer {
  margin-bottom: 10px;
}

.bg_container {
  border: 1px solid #f1f1f1;
}

.panel {
  background: #fff;
}

.panel-body {
  background: #fff;
}

.margin_top {
  margin-top: 65px;
}

.wrapper {
  margin: 0px;
  padding: 60px 30px 0px 30px;
}

.doctor {
  color: #2f80bf;
}

.hospital {
  color: #2f80bf;
}

hr {
  border-top: 1px solid #f1f1f1;
}

.panel_button {
  margin: 10px;
}

.left_panel {
  border-right: 1px solid #ccc;
  margin-left: -15px;
}

th {
  border-bottom: 0px;
}

.col-md-4 {
  margin-right: 0px !important;
}

.patient {
  font-size: 12px;
}

.patient_name {
  font-size: 12px;
}

.prescription {
  font-size: 12px;
}

p {
  font-size: 12px;
}

.top_logo {
  margin-bottom: 10px;
}

/* @media print {

  .left_panel {
    border-right: 1px solid #ccc;
    margin-left: -15px;
  }

  .wrapper {
    margin: 0px;
    padding: 0px 10px 0px 0px;
  }

  .patient {
    width: 23%;
    float: left;
  }

  .patient_name {
    width: 31%;
    float: left;
  }

  .text-right {
    float: right;
  }

  .doctor {
    color: #2f80bf !important;
    font-size: 25px;
  }

  .hospital {
    color: #2f80bf !important;
  }

  .prescription {
    float: left;
  }

  .top_title {
    width: 70%;
  }

  .top_logo {
    width: 30%;
    margin-bottom: 10px;
  }

  .col-md-6 {
    width: 50%;
    float: left;
  }

  .col-md-5 {
    width: 45%;
    float: left;
  }

  .col-md-7 {
    width: 55%;
    float: left;
  }

  th {
    border-bottom: 0px;
  }
} */
</style>